// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}

a {
    color: blue;            /* Set link color to blue */
    text-decoration: underline; /* Add underline to links */
}

.ui.form {
    display: grid;
}

.ui.form > * {
    margin-top: 5px;
}





`, "",{"version":3,"sources":["webpack://./src/components/LoginModal/LoginModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW,aAAa,2BAA2B;IACnD,0BAA0B,EAAE,2BAA2B;AAC3D;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["body.active-modal {\r\n    overflow-y: hidden;\r\n}\r\n\r\n.btn-modal {\r\n    padding: 10px 20px;\r\n    display: block;\r\n    margin: 100px auto 0;\r\n    font-size: 18px;\r\n}\r\n\r\n.modal, .overlay {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    position: fixed;\r\n}\r\n\r\n.overlay {\r\n    background: rgba(49,49,49,0.8);\r\n}\r\n.modal-content {\r\n    position: absolute;\r\n    top: 40%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    line-height: 1.4;\r\n    background: #f1f1f1;\r\n    padding: 14px 28px;\r\n    border-radius: 3px;\r\n    max-width: 600px;\r\n    min-width: 300px;\r\n}\r\n\r\n.close-modal {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    padding: 5px 7px;\r\n}\r\n\r\na {\r\n    color: blue;            /* Set link color to blue */\r\n    text-decoration: underline; /* Add underline to links */\r\n}\r\n\r\n.ui.form {\r\n    display: grid;\r\n}\r\n\r\n.ui.form > * {\r\n    margin-top: 5px;\r\n}\r\n\r\n\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
